import mineSwiper from './swiper'
import { Toast, Image, NavBar, Icon } from 'vant'
export default {
  components:{
    mineSwiper,
    'van-image' : Image,
    'van-icon' : Icon,
    'van-nav-bar' : NavBar,
  },
  data () {
    return {
      apis: {
        getAllArea: '/baseArea/getAllArea'
      },
      content: [],
      isDownload:false,
    }

  },
  mounted(){
    this.init();//城市名片初始化
  },
  created(){

    this.getShareInfo(
      this.$global.shareTitle,
      this.$global.visitUrl,
      this.$global.shareImage,
      this.$global.shareIntro,
    );
  },
  methods: {
    init () {
      const me = this;
      me.$get({
        url:me.apis.getAllArea,
        params:{
          adCode:"qy-ls"
        }
      }).then(rep=>{
        me.content=rep

      })
    },
    routerPush(obj){
      let arr={
        areaId:obj.areaId,
        name:obj.name,
        cityCode:obj.cityCode
      }
      this.$router.push(`/cityDetails/?obj=${JSON.stringify(arr)}`)
    },
    onClickLeft () {
      this.$router.go(-1)
    }
  }
}
