<template>
    <div class="cityCard">
      <van-nav-bar
        title="城市名片"
        @click-left="onClickLeft"
      >
        <template #left>
          <van-icon color="#141212"  :size="22" name="arrow-left" slot="left" />
        </template>
      </van-nav-bar>
      <template v-if="!isDownload">
        <div class="list" >
          <div class="list_title d_f ali_c j_s">
            <span>热门区县</span>
            <!--<img src="./img/cc_s.png" alt="" class="cur_p" @click="isDownload = !isDownload">-->
          </div>
          <div class="list_imgList">
            <div class="item" @click="routerPush(item)" v-show="item.hot" v-for="(item,i) in content" :key="i">
              <img :src="item.cover" alt="">
              <span v-text="item.name"></span>
            </div>
          </div>
        </div>
        <div class="list" >
          <div class="list_title">全部区县</div>
          <div class="list_imgList">
            <div class="item" @click="routerPush(item)" v-for="(item,i) in content" :key="i">
              <img :src="item.cover" alt="">
              <span v-text="item.name"></span>
            </div>
          </div>
        </div>

      </template>
      <!--<template v-else>
        <div class="cd_top t_r">
          <img src="./img/cc_h.png" alt="" class="cur_p" @click="isDownload = !isDownload">
        </div>
        <div class="cd_content">
          <mineSwiper :content="content"></mineSwiper>
        </div>
      </template>-->
    </div>
</template>

<script src="./cityCard.js">

</script>
<style scoped src="./cityCard.css">
</style>
